<template>
  <div class="ScaleTask bg-white">
    <Row class="px-1">
      <i-col span="18" class="align-justify">
        <Button
          type="default"
          icon="md-add"
          size="large"
          class="mr-1"
          @click="addTask"
        ></Button>
        <!-- <Dropdown trigger="click">
          <DropdownMenu slot="list">
            <div style="text-align: right;margin:10px;">
              <Button type="text" @click="addTask">个体任务</Button>
            </div>
            <div style="text-align: right;margin:10px;">
              <Button type="text" @click="addGroupTask">团体任务</Button>
            </div>
          </DropdownMenu>
        </Dropdown> -->
        <Select
          class="mr-1"
          size="large"
          style="width: 10rem"
          v-model="taskType"
          @on-select="selectType"
          clearable
          @on-clear="clear"
        >
          <Option
            v-for="item in releaseType"
            :disabled="item.disabled"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
        <i-input
          v-model="inputValue"
          size="large"
          style="width: 15rem"
          class="el-inline mr-1"
          placeholder="输入关键字进行查询"
        >
          <Button
            slot="append"
            icon="md-search"
            style="width: 2.5rem"
            @click="getScaleTask"
          ></Button>
        </i-input>
        <!-- <Button type="default" size="large" class="mr-1">批量导出</Button> -->
      </i-col>
      <i-col span="6" class="align-right colHeigth">
        <!-- <Checkbox @on-change="changeStatus(statusChar)">{{statusChar}}</Checkbox> -->
      </i-col>
      <i-col span="24">
        <Table
          border
          :loading="searching"
          ref="usersTable"
          :columns="tColumns"
          :data="tData"
          class="mt-1"
        >
          <div slot-scope="{ row }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="taskDetails(row)"
              >详情</Button
            >
            <Button size="small" @click="deleteTask(row)">删除</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
    <Drawer
      title="任务详情"
      v-model="detailDraw"
      width="600"
      :mask-closable="false"
    >
      <Form ref="formValidate" :model="taskDetail">
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="任务名称" label-position="left">
              <Input v-model="taskDetail.taskName" readonly />
            </FormItem>
          </i-col>
        </Row>
        <FormItem label="任务说明" label-position="top">
          <Input
            type="textarea"
            v-model="taskDetail.taskDesc"
            :rows="4"
            readonly
            placeholder="请输入任务说明"
          />
        </FormItem>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="选择量表" label-position="top">
              <div v-for="item in taskDetail.scaleName" style="width: 100%">
                <Tag type="dot" color="primary">{{ item }}</Tag>
              </div>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="结束时间" label-position="top">
              <Tag type="dot" color="primary">{{ taskDetail.endTime }}</Tag>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="选择用户" label-position="top">
              <Tag type="dot" color="primary">{{ taskDetail.testName }}</Tag>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </Drawer>
  </div>
</template>

<script>
export default {
  name: "ScaleTask",
  data() {
    return {
      taskDetail: {},
      detailDraw: false,
      searching: true,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      state: 1,
      statusChar: "已过期或已失效任务",
      taskType: -1,
      inputValue: "",
      timelist: [
        {
          value: "1",
          label: "06:00",
        },
        {
          value: "2",
          label: "07:00",
        },
        {
          value: "3",
          label: "08:00",
        },
        {
          value: "4",
          label: "09:00",
        },
        {
          value: "5",
          label: "10:00",
        },
        {
          value: "6",
          label: "11:00",
        },
        {
          value: "7",
          label: "12:00",
        },
        {
          value: "8",
          label: "13:00",
        },
        {
          value: "9",
          label: "14:00",
        },
        {
          value: "10",
          label: "15:00",
        },
        {
          value: "11",
          label: "16:00",
        },
        {
          value: "12",
          label: "17:00",
        },
        {
          value: "13",
          label: "18:00",
        },
        {
          value: "14",
          label: "19:00",
        },
        {
          value: "15",
          label: "20:00",
        },
        {
          value: "16",
          label: "21:00",
        },
        {
          value: "17",
          label: "22:00",
        },
        {
          value: "18",
          label: "23:00",
        },
        {
          value: "19",
          label: "00:00",
        },
      ],
      userList: [],
      userLoading: true,
      task: {
        r_type: 1,
        unitId: [],
      },
      scaleList: [],
      companyData: [],
      cascaderValue: [],
      releaseType: [
        {
          value: "1",
          label: "个人任务",
        },
        {
          value: "2",
          label: "团体任务",
        },
      ],
      draw: false,
      tData: [],
      selectValue: "usersName",
      theight: window.innerHeight - 175,
      tColumns: [
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "任务名称",
          key: "taskName",
          minWidth: 150,
          ellipsis: true,
        },
        {
          title: "任务描述",
          key: "taskDesc",
          minWidth: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "结束时间",
          key: "endTime",
          render: (h, params) => {
            let dt = this.myjs.getDateTimeNumCutT(params.row.endTime);
            return h("span", dt);
          },
          sortable: true,
          minWidth: 100,
        },
        {
          title: "类型",
          key: "taskType",
          minWidth: 60,
          render: (h, params) => {
            const text = params.row.taskType === 1 ? "个人任务" : "团体任务";
            return h("span", text);
          },
        },
        {
          title: "任务对象",
          key: "testName",
          minWidth: 100,
        },
        {
          title: "发布人",
          key: "adminName",
          minWidth: 100,
        },
        {
          title: "发布时间",
          key: "setTime",
          minWidth: 100,
          render: (h, params) => {
            let dt = this.myjs.getDateTimeNumCutT(params.row.setTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "任务状态",
          key: "completeStatus",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const color = row.completeStatus === 1 ? "primary" : "warning";
            const text = row.completeStatus === 1 ? "已完成" : "进行中";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 102,
        },
      ],
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getScaleTask();
  },
  methods: {
    clear() {
      this.taskType = -1;
      this.getScaleTask();
    },
    changeSize(e) {
      this.pageSize = e;
      this.getScaleTask();
    },
    changePage(e) {
      this.pageNum = e;
      this.getScaleTask();
    },
    getScaleTask() {
      this.searching = true;
      let selectRegion = localStorage.getItem("SelectScaleTask");
      let adminId = localStorage.getItem("adminId");
      this.$get("Task/GetScaleTaskList", {
        requestName: "getScaleTaskList",
        status: this.state,
        taskType: this.taskType,
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        selectRegion: selectRegion,
        adminId: adminId,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
      });
    },
    changeStatus(str) {
      if (str == "已过期或已失效任务") {
        this.statusChar = "进行中任务";
        this.state = 0;
      } else {
        this.statusChar = "已过期或已失效任务";
        this.state = 1;
      }
      this.pageNum = 1;
      this.getScaleTask();
    },
    selectType(e) {
      this.taskType = e.value;
      this.pageNum = 1;
      this.getScaleTask();
    },
    cancelRelease() {
      this.draw = false;
    },
    getUser(query) {
      let u_list = [];
      this.$get("Customer/GetUserByQuery", {
        keyword: query,
      }).then((res) => {
        this.userList = res.data.responseList;
        this.userLoading = false;
      });
    },
    taskDetails(row) {
      this.detailDraw = true;
      this.$get("Task/GetTaskDetail", {
        taskId: row.taskId,
      }).then((res) => {
        this.taskDetail = res.data.response;
        this.taskDetail.endTime =
          this.myjs.dateFormat(res.data.response.endTime) +
          " " +
          this.myjs.timeFormat(res.data.response.endTime);
      });
    },
    deleteTask(row) {
      this.$Modal.confirm({
        title: "提示",
        content: "是否删除?",
        loading: true,
        onOk: () => {
          this.$post("Task/DeleteTask", {
            taskId: row.taskId,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
              this.getScaleTask();
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
      });
    },
    addTask() {
      // this.draw = true;
      this.$router.push({
        path: "/ReleaseScaleTask",
      });
    },
    addGroupTask() {
      this.$router.push({
        path: "/ReleaseGroupScaleTask",
      });
    },
    selectR_Type(e) {
      this.task.r_type = e.value;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
</style>
